@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
@tailwind base;
@layer base {
    h1 {
        @apply text-2xl;
    }
    h2 {
        @apply text-xl;
    }
    h3 {
        @apply text-lg;
    }
}

@tailwind components;
@tailwind utilities;
* {
    font-family: "Sora", sans-serif !important;
    font-weight: 500;
}

body {
    background-color: #ffffff !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 4px;
    border: 1px solid #d5d5d5;
}


/* Track */

 ::-webkit-scrollbar-track {
    background: #fff;
    border-radius: 2px;
    height: 100px;
}


/* Handle */

 ::-webkit-scrollbar-thumb {
    background: #898989;
    border-radius: 25px;
    width: 1px;
}


/* Handle on hover */

 ::-webkit-scrollbar-thumb:hover {
    background: #555;
}